<template>
    <layout-centered>
        <div class="p-4">
            <h1>Password reset page</h1>
        </div>
    </layout-centered>
</template>

<script>

import LayoutCentered from "@/layouts/LayoutCentered";

export default {
    name: "PagePasswordReset",
    components: { LayoutCentered },
    computed: {
        appTitle() {
            return process.env.VUE_APP_TITLE;
        }
    }
}
</script>